var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"page-title-header"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
            name: 'DashboardHome',
          }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(_vm._s(_vm.isReadOnly ? "查看預約班表" : _vm.isEditing ? "編輯預約班表" : "新增預約班表")+" ")])],1)],1)]),_c('div',{staticClass:"email-wrapper wrapper"},[_c('div',{staticClass:"row bg-white"},[_c('div',{staticClass:"col-md-12"},[_c('b-overlay',{attrs:{"show":_vm.showLoading}},[_c('b-card',[_c('b-form-select',{staticClass:"col-12 col-xl-2 mb-4",attrs:{"options":_vm.bookingPresetOptions,"disabled":""},model:{value:(_vm.bookingShift.booking_preset_id),callback:function ($$v) {_vm.$set(_vm.bookingShift, "booking_preset_id", $$v)},expression:"bookingShift.booking_preset_id"}}),_c('b-form-group',{staticStyle:{"width":"100%"},attrs:{"label":"類型","label-cols-sm":"12","label-cols-lg":"2","label-size":"sm"}},[_c('b-form-radio-group',{attrs:{"options":_vm.shiftTypeOptions,"disabled":_vm.isReadOnly},model:{value:(_vm.bookingShift.shift_type),callback:function ($$v) {_vm.$set(_vm.bookingShift, "shift_type", $$v)},expression:"bookingShift.shift_type"}})],1),_c('b-form-group',{staticStyle:{"width":"100%"},attrs:{"label-cols-sm":"12","label-cols-lg":"2","label-size":"sm"}},[_c('b-form-checkbox',{attrs:{"disabled":_vm.isReadOnly},model:{value:(_vm.isFullDay),callback:function ($$v) {_vm.isFullDay=$$v},expression:"isFullDay"}},[_vm._v(" 全天 ")])],1),_c('b-form-group',{attrs:{"label-cols":"12","label-cols-lg":"2","label-size":"sm","label":"開始時間"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-xl-3 mb-lg-0 mb-2"},[_c('datepicker',{attrs:{"placeholder":"請選擇日期","bootstrap-styling":"","format":"yyyy-MM-dd","language":_vm.zh,"disabled":_vm.isReadOnly,"input-class":{
                      'is-invalid': _vm.v$.startAt.date.$error || _vm.v$.dateTimeComparison.valid.$error
                    }},model:{value:(_vm.startAt.date),callback:function ($$v) {_vm.$set(_vm.startAt, "date", $$v)},expression:"startAt.date"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.v$.startAt.date.$error}},[_vm._v(" 此欄位為必填 ")])],1),_c('div',{staticClass:"col-12 col-xl-3"},[_c('vue-timepicker',{attrs:{"format":"HH:mm","disabled":_vm.isReadOnly|| _vm.isFullDay,"input-class":[
                      'form-control',
                      {
                        'is-invalid': _vm.v$.startAt.time.HH.$error ||
                          _vm.v$.startAt.time.mm.$error ||
                          _vm.v$.dateTimeComparison.valid.$error
                      }
                    ]},model:{value:(_vm.startAt.time),callback:function ($$v) {_vm.$set(_vm.startAt, "time", $$v)},expression:"startAt.time"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.v$.startAt.time.HH.$error || !_vm.v$.startAt.time.mm.$error}},[_vm._v(" 此欄位為必填 ")])],1)])]),_c('b-form-group',{attrs:{"label-cols":"12","label-cols-lg":"2","label-size":"sm","label":"結束時間"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-xl-3 mb-lg-0 mb-2"},[_c('datepicker',{attrs:{"placeholder":"請選擇日期","bootstrap-styling":"","format":"yyyy-MM-dd","language":_vm.zh,"input-class":{
                      'is-invalid': _vm.v$.endAt.date.$error || _vm.v$.dateTimeComparison.valid.$error
                    },"disabled":_vm.isReadOnly},model:{value:(_vm.endAt.date),callback:function ($$v) {_vm.$set(_vm.endAt, "date", $$v)},expression:"endAt.date"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.v$.endAt.date.$error}},[_vm._v(" 此欄位為必填 ")]),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.v$.dateTimeComparison.valid.$invalid}},[_vm._v(" 結束時間必須大於起始時間 ")])],1),_c('div',{staticClass:"col-12 col-xl-3"},[_c('vue-timepicker',{attrs:{"input-class":[
                      'form-control',
                      {
                        'is-invalid': _vm.v$.endAt.time.HH.$error ||
                          _vm.v$.endAt.time.mm.$error ||
                          _vm.v$.dateTimeComparison.valid.$error
                      }
                    ],"disabled":_vm.isReadOnly|| _vm.isFullDay},model:{value:(_vm.endAt.time),callback:function ($$v) {_vm.$set(_vm.endAt, "time", $$v)},expression:"endAt.time"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.v$.endAt.time.HH.$error || !_vm.v$.endAt.time.mm.$error}},[_vm._v(" 此欄位為必填 ")])],1)])]),_c('div',{staticClass:"d-flex justify-content-center mt-4"},[_c('b-button',{staticClass:"mr-3",attrs:{"variant":"outline-danger"},on:{"click":_vm.goBackOrList}},[_vm._v(" 返回 ")]),_c('b-button',{staticClass:"mr-3",attrs:{"variant":"success"},on:{"click":_vm.submit}},[_vm._v(" 儲存 ")])],1)],1)],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }