<template>
  <div>
    <!-- Page Header Start -->
    <div class="page-title-header">
      <div class="page-header">
        <b-breadcrumb class="m-0">
          <b-breadcrumb-item
            :to="{
              name: 'DashboardHome',
            }"
          >
            <i class="fa fa-home"></i>
          </b-breadcrumb-item>

          <b-breadcrumb-item active
          >{{ isReadOnly ? "查看預約班表" : isEditing ? "編輯預約班表" : "新增預約班表" }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>
    <!-- Page Header End -->

    <!-- Form Start -->
    <div class="email-wrapper wrapper">
      <div class="row bg-white">
        <div class="col-md-12">
          <b-overlay :show="showLoading">
            <b-card>
              <b-form-select
                class="col-12 col-xl-2 mb-4"
                v-model="bookingShift.booking_preset_id"
                :options="bookingPresetOptions"
                disabled
              ></b-form-select>
              <b-form-group
                label="類型"
                label-cols-sm="12"
                label-cols-lg="2"
                label-size="sm"
                style="width: 100%"
              >
                <b-form-radio-group
                  v-model="bookingShift.shift_type"
                  :options="shiftTypeOptions"
                  :disabled="isReadOnly"
                ></b-form-radio-group>
              </b-form-group>
              <b-form-group
                label-cols-sm="12"
                label-cols-lg="2"
                label-size="sm"
                style="width: 100%"
              >
                <b-form-checkbox
                  v-model="isFullDay"
                  :disabled="isReadOnly"
                >
                  全天
                </b-form-checkbox>
              </b-form-group>
              <!-- 表單開始時間 Start -->
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="開始時間"
              >
                <div class="row">
                  <div class="col-12 col-xl-3 mb-lg-0 mb-2">
                    <datepicker
                      placeholder="請選擇日期"
                      v-model="startAt.date"
                      bootstrap-styling
                      format="yyyy-MM-dd"
                      :language="zh"
                      :disabled="isReadOnly"
                      :input-class="{
                        'is-invalid': v$.startAt.date.$error || v$.dateTimeComparison.valid.$error
                      }"
                    ></datepicker>
                    <b-form-invalid-feedback :state="!v$.startAt.date.$error">
                      此欄位為必填
                    </b-form-invalid-feedback>
                  </div>

                  <div class="col-12 col-xl-3">
                    <vue-timepicker
                      format="HH:mm"
                      v-model="startAt.time"
                      :disabled="isReadOnly|| isFullDay"
                      :input-class="[
                        'form-control',
                        {
                          'is-invalid': v$.startAt.time.HH.$error ||
                            v$.startAt.time.mm.$error ||
                            v$.dateTimeComparison.valid.$error
                        }
                      ]"
                    ></vue-timepicker>
                    <b-form-invalid-feedback
                      :state="
                    !v$.startAt.time.HH.$error || !v$.startAt.time.mm.$error
                  "
                    >
                      此欄位為必填
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </b-form-group>
              <!-- 表單開始時間 End -->
              <!-- 表單結束時間 Start -->
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="結束時間"
              >
                <div class="row">
                  <div class="col-12 col-xl-3 mb-lg-0 mb-2">
                    <datepicker
                      placeholder="請選擇日期"
                      v-model="endAt.date"
                      bootstrap-styling
                      format="yyyy-MM-dd"
                      :language="zh"
                      :input-class="{
                        'is-invalid': v$.endAt.date.$error || v$.dateTimeComparison.valid.$error
                      }"
                      :disabled="isReadOnly"
                    ></datepicker>
                    <b-form-invalid-feedback :state="!v$.endAt.date.$error">
                      此欄位為必填
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback
                      :state="!v$.dateTimeComparison.valid.$invalid"
                    >
                      結束時間必須大於起始時間
                    </b-form-invalid-feedback>
                  </div>

                  <div class="col-12 col-xl-3">
                    <vue-timepicker
                      :input-class="[
                        'form-control',
                        {
                          'is-invalid': v$.endAt.time.HH.$error ||
                            v$.endAt.time.mm.$error ||
                            v$.dateTimeComparison.valid.$error
                        }
                      ]"
                      v-model="endAt.time"
                      :disabled="isReadOnly|| isFullDay"
                    ></vue-timepicker>
                    <b-form-invalid-feedback
                      :state="
                    !v$.endAt.time.HH.$error || !v$.endAt.time.mm.$error
                  "
                    >
                      此欄位為必填
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </b-form-group>
              <!-- 表單結束時間 End -->

              <!-- 表單底部 Start -->
              <div class="d-flex justify-content-center mt-4">
                <b-button
                  class="mr-3"
                  @click="goBackOrList"
                  variant="outline-danger"
                >
                  返回
                </b-button>
                <b-button
                  class="mr-3"
                  @click="submit"
                  variant="success"
                >
                  儲存
                </b-button>
              </div>
              <!-- 表單底部 End -->
            </b-card>
          </b-overlay>
        </div>
      </div>
    </div>
    <!-- Form End -->
  </div>
</template>

<script>
import { zh } from "vuejs-datepicker/dist/locale";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import bookingApi from "@/apis/booking";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import { mapGetters } from "vuex";
import {format, getHours, getMinutes, isBefore, set} from "date-fns";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";

export default {
  components: { Datepicker, VueTimepicker},
  setup: () => ({ v$: useVuelidate({ $scope: false }) }),
  validations() {
    return {
      startAt: {
        date: {
          required,
        },
        time: {
          HH: { required },
          mm: { required },
        },
      },
      endAt: {
        date: { required },
        time: {
          HH: { required },
          mm: { required },
        },
      },
      dateTimeComparison: {
        valid: () => {
          if (!this.startAt.date || !this.endAt.date ||
            !this.startAt.time.HH || !this.startAt.time.mm ||
            !this.endAt.time.HH || !this.endAt.time.mm) {
            return true;
          }

          const startDate = set(new Date(this.startAt.date), {
            hours: parseInt(this.startAt.time.HH || 0, 10),
            minutes: parseInt(this.startAt.time.mm || 0, 10),
            seconds: 0
          });

          const endDate = set(new Date(this.endAt.date), {
            hours: parseInt(this.endAt.time.HH || 0, 10),
            minutes: parseInt(this.endAt.time.mm || 0, 10),
            seconds: 0
          });

          return isBefore(startDate, endDate);
        }
      }
    };
  },
  data() {
    return {
      consts,
      zh,
      showLoading: false,
      validationErrors: null,
      isFullDay: false,
      shiftTypeOptions: [
        { text: '休假', value: 'leave' },
        { text: '上班(尚未提供)', value: 'duty', disabled: true },
      ],
      bookingPreset: {
        name: null,
        config: {
          enabled_selected_staff_shift: false,
        },
      },
      bookingPresetOptions: [],
      bookingShift: {
        id: null,
        booking_preset_id: null,
        staff_id: null,
        branch_id: null,
        start_at: null,
        end_at: null,
        shift_type: "leave",
      },
      startAt: {
        date: null,
        time: {
          HH: null,
          mm: null,
        },
      },
      endAt: {
        date: null,
        time: {
          HH: null,
          mm: null,
        },
      }
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters("route", ["routeQuery", "routeParams"]),
    ...mapGetters({
      getModuleConfig: "dashboardModule/getConfig",
    }),
    isReadOnly() {
      return this.$route.name === "BookingShiftView";
    },
    isEditing() {
      return this.$route.name === "BookingShiftEdit";
    },
    isCreate() {
      return this.$route.name === "BookingShiftCreate";
    },
  },
  watch: {
    startAt: {
      handler(value) {
        this.bookingShift.start_at = format(
          set(new Date(value.date), {
            hours: Number(value.time.HH),
            minutes: Number(value.time.mm),
            seconds: 0,
          }),
          "yyyy-MM-dd HH:mm:ss"
        );
      },
      deep: true,
    },
    endAt: {
      handler(value) {
        this.bookingShift.end_at = format(
          set(new Date(value.date), {
            hours: value.time.HH,
            minutes: value.time.mm,
            seconds: 59,
          }),
          "yyyy-MM-dd HH:mm:ss"
        );
      },
      deep: true,
    },
    isFullDay(newVal) {
      if (newVal) {
        this.startAt.time = { HH: '00', mm: '00' };
        this.endAt.time = { HH: '23', mm: '59' };
      }
    }
  },
  methods: {
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    async init() {
      this.showLoading = true;
      if (this.isCreate) {
        this.isFullDay = true;
        this.bookingPreset.id = this.$route.query.preset_id;
        this.bookingShift.booking_preset_id = this.$route.query.preset_id;
      } else {
        await this.fetchBookingShift();
      }
      await this.fetchBookingPreset();
      this.showLoading = false;
    },
    async submit() {
      const result = await this.v$.$validate();
      if (!result) return;

      try {
        this.bookingShift.start_at = this.formatDatetime(this.startAt);
        this.bookingShift.end_at = this.formatDatetime(this.endAt);

        if (this.isCreate) {
          await bookingApi.createBookingShift(this.bookingPreset.id, this.bookingShift);
        } else {
          await bookingApi.updateBookingShift(this.bookingShift.id, this.bookingShift);
        }

        this.$swal("成功", "儲存成功", "success");
        this.goBackOrList();
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "儲存失敗", "error");
      }
    },
    async fetchBookingPreset() {
      if (!this.bookingPreset.id) {
        this.$swal("錯誤", "無法取得讀取 ID", "error");
        return;
      }

      try {
        let response = await bookingApi.getBookingPreset(this.bookingPreset.id);

        if (!response.data.data.config) {
          response.data.data.config = {
            enabled_selected_staff_shift: false,
          };
        }

        this.bookingPreset = response.data.data;
        this.bookingPresetOptions = [
          {
            text: this.bookingPreset.name,
            value: this.bookingPreset.id,
          },
        ];

        if (
          !this.bookingPreset.config?.enabled_selected_staff_shift
        ) {
          this.$swal(
            "提醒",
            "無法設定班表，請聯絡管理員設定",
            "warning"
          );
          this.goBackOrList();
          return;
        }

      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料錯誤", "error");
        this.goBackOrList();
      }
    },
    async fetchBookingShift() {
      try {
        const response = await bookingApi.getBookingShift(
          this.$route.params.id
        );
        this.bookingShift = response.data.data;
        this.startAt = this.formatDatetimeRevert(this.bookingShift.start_at);
        this.endAt = this.formatDatetimeRevert(this.bookingShift.end_at);

        if (this.startAt.time.HH === '00' && this.startAt.time.mm === '00' && this.endAt.time.HH === '23' && this.endAt.time.mm === '59') {
          this.isFullDay = true;
        }

        this.bookingPreset.id = this.bookingShift.booking_preset_id;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取錯誤", "error");
      }
    },
    goBackOrList() {
      this.$router.push({name: 'BookingShiftList', query: this.routeQuery });
    },
    formatDatetime(datetime) {
      return format(
        set(new Date(datetime.date), {
          hours: Number(datetime.time.HH),
          minutes: Number(datetime.time.mm),
          seconds: 0,
        }),
        "yyyy-MM-dd HH:mm:ss"
      );
    },
    formatDatetimeRevert(datetime) {
      return {
        date: new Date(datetime),
        time: {
          HH: String(getHours(new Date(datetime))).padStart(2, "0"),
          mm: String(getMinutes(new Date(datetime))).padStart(2, "0"),
        },
      }
    },
  },
};
</script>

